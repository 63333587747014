import { Icon } from '@iconify/react';
import googleFill from '@iconify/icons-eva/google-fill';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Grid, Link, Divider, Container, Typography, IconButton, Stack, Toolbar, Box } from '@material-ui/core';
// routes
import { PATH_PAGE } from '../../routes/paths';
//
import Logo from '../../components/Logo';
import FooterMenuNew from './FooterMenuNew';
import { MHidden } from 'src/components/@material-extend';
import Cloud from '../../images/cloud1.jpg';
import Consulting from '../../images/consulting.jpg';
import Internet from '../../images/internet.jpeg';
import Experince from '../../images/experience.jpg';
import SeedToCRM from '../../images/seed-crm.jpg';
import CallCenter from '../../images/call-center.jpg';
import ResturentPOS from '../../images/pos-resturent.jpeg';
import RFIDPOS from '../../images/rfid-pos.jpg';
import Information from '../../images/information.jpg';
import Health from '../../images/health.jpg';
import Education from '../../images/education.jpg';
import Manufacturing from '../../images/manufacturing.jpg';
import Retail from '../../images/retail1.jpg';
import Transportation from '../../images/transportation.jpeg';
import TravelHospitality from '../../images/travelhospitality.jpeg';
import Government from '../../images/government.jpeg';
import Home1 from '../../images/Slider-1.png';
import Offshore from '../../images/CustomNew.png';
import Home2 from '../../images/CustomNew1.jpeg';
import Home3 from '../../images/Slider-3.jpeg';
import Home4 from '../../images/Slider-2.png';
import CustomSoftware from '../../images/custom.jpg';
import { Helmet } from 'react-helmet';

// ----------------------------------------------------------------------

const SOCIALS = [
  { name: 'FaceBook', icon: facebookFill },
  { name: 'Google', icon: googleFill },
  { name: 'Linkedin', icon: linkedinFill },
  { name: 'Twitter', icon: twitterFill }
];

const LINKS = [
  {
    headline: 'Industries',
    children: [
      { 
        title: 'Information & Services',
        imgSrc: Information,
        title1: 'information-and-services',
        industry: true,
        contactText: 'Contact Us to Learn More About Unlocking Growth from Information',
        homeTitle: 'Expand your strategy to unlock new growth from information',
        description: "Adapting to evolving audience needs, diverse consumption patterns, and actionable insights fuels the information economy. At skyniche technologies, we provide comprehensive services and expertise across the information landscape to help you modernize your business model, drive innovation, enhance agility, and meet the fast-paced demands of digital transformation.",
      },
      {
        title: 'Manufacturing',
        imgSrc: Manufacturing,
        title1: 'manufacturing',
        contactText: 'Get in Touch for Your Digital Manufacturing Needs',
        homeTitle: 'Embracing Digitalization in Manufacturing Processes',
        industry: true,
        description: "Our mission is to empower your manufacturing business with cutting-edge solutions that drive productivity to new heights. Through advanced analytics, automation, artificial intelligence, and integration of smart factory technologies, we ensure you make informed decisions based on real-time data. This transformative approach not only enhances operational efficiency but also positions your business for sustained growth in today's competitive landscape. Let us partner with you to unlock the full potential of your manufacturing operations.<br/><br/>Take the first step with manufacturing digital services",
      },
      {
        title: 'Education',
        imgSrc: Education,
        title1: 'education',
        contactText: 'Contact Us to Learn More About the Future of Educational Technology and Ongoing Relearning',
        homeTitle: 'The future of educational technology lies in ongoing relearning.',
        industry: true,
        description: "There is a transformative shift happening in what and how we teach, and even in the methods of learning itself.The benefit? Emerging chances for educational providers to expand their enterprises by offering tailored learning experiences that leverage educational technology and IT solutions, while prioritizing the sense of importance for learners.",
      },
      {
        title: 'Transportation & Logistics',
        imgSrc: Transportation,
        title1: 'transportation-and-logistics',
        contactText: 'Contact Us for Advanced Transportation Technology Solutions',
        homeTitle: 'Navigating transformation through advanced transportation technology solutions',
        industry: true,
        description: "At skyniche technologies, we are dedicated to pioneering innovative solutions in transportation and logistics, harnessing digital advancements to optimize operational efficiency and elevate customer satisfaction. By embracing cutting-edge digital innovations, we empower transportation and logistics leaders to enhance supply chain visibility, access real-time data, and accelerate global customer order fulfillment through advanced technology solutions. Stay at the forefront of industry evolution by leveraging state-of-the-art technologies such as IoT, machine learning, predictive analytics, and blockchain with skyniche technologies.",
      },
      { 
        title: 'Government',
        imgSrc: Government,
        title1: 'government',
        contactText: 'Get in Touch to Improve Citizen Experience: Modernizing Government Services',
        homeTitle: 'Enhancing Citizen Experience through Modernizing Government Services',
        industry: true,
        description: "skyniche Technologies has significantly contributed to government initiatives by implementing advanced technologies that enhance citizen experiences. Our expertise has resulted in the development and deployment of numerous applications across Nigeria, one of the fastest-growing economies in Africa. These solutions have streamlined processes, improved service delivery, and fostered greater connectivity between citizens and government services, paving the way for more efficient and responsive governance.",
      },
      {
        title: 'Healthcare',
        imgSrc: Health,
        title1: 'health-care',
        contactText: 'Get in Touch for Your Digital Healthcare Solutions',
        homeTitle: 'Transforming digital<br/>healthcare services',
        industry: true,
        description: "skyniche Technologies is assisting numerous healthcare clients in navigating industry transformations, developing and optimizing healthcare IT solutions and applications to enhance and streamline the patient care journey for millions.",
      },
      {
        title: 'Retail',
        imgSrc: Retail,
        title1: 'retail',
        contactText: 'Contact Form Submission: Insights on The Evolving Pillars of Modern Retail',
        homeTitle: 'The evolving pillars of modern retail<br/>strategies',
        industry: true,
        description: "skyniche Technologies is leading retail partners through industry transformation, developing and deploying digital solutions that enhance and simplify the shopping journey for numerous customers. Through seamless integration across the entire value chain, we harmonize customer interaction with backend operations, establishing the groundwork for your strategic expansion and operational excellence.",
      },
      {
        title: 'Travel & Hospitality',
        imgSrc: TravelHospitality,
        title1: 'travel-and-hospitality',
        contactText: 'Enhance Your Experience with Our Services: Get in Touch',
        homeTitle: 'Transforming the way customers experience our services',
        industry: true,
        description: "As the travel and hospitality sector aims to recover, digital innovation emerges as a crucial ally. From AI-driven chatbots to adaptive booking systems, businesses can ignite fresh momentum while enhancing customer satisfaction and operational efficiency. We specialize in navigating diverse avenues and developing tailored digital strategies to thrive in the post-pandemic landscape.",
      },
    ]
  },
  {
    headline: 'Services',
    children: [
      { title: 'Custom Software',
        subheading: "Cultivating Innovation for Sustainable Growth :",
        title1: 'custom-software-development',
        introPage: 'Custom Solutions Drive',
        introPage1: 'Businesses',
        btnText: 'Get Details',
        img: Home2,
        homeTitle: 'Empower Your Business with Custom Solutions<br/> for Today and Tomorrow',
        imgSrc: Offshore,
        description: "At skyniche Technologies, we partner with businesses to develop, modernize, and manage custom software solutions with unparalleled speed and precision. Leveraging cutting-edge technologies, agile methodologies, and deep industry insights, we deliver tailor-made applications that enhance operational efficiency and drive innovation. Our bespoke software solutions are designed to scale with your business, ensuring long-term growth and adaptability. By integrating automation and intelligent tools, we empower you to do more and think bigger. Transform your business with skyniche Technologies—your partner for future-ready, custom software solutions.",
        focusHeading: 'Focus Area of our work : ',
        focuspoints: [
          { id: 1, label: "Web Application Development", color: '#0060a0' },
          { id: 2, label: "Desktop Application Development", color: '#0b6ebf' },
          { id: 3, label: "SaaS Application Development", color: '#167acc' },
          { id: 4, label: "IoT App Development", color: '#2188da' },
          { id: 5, label: "AI Development", color: '#2c94e7' },
          { id: 6, label: "Mobile App Development", color: '#37a1f4' },
          { id: 7, label: "Cloud Application Development", color: '#42adff' },
          { id: 8, label: "Enterprise Software Solutions", color: '#4dbaff' },
          { id: 9, label: "Custom API Development", color: '#58c6ff' },
          { id: 10, label: "Blockchain Development", color: '#63d3ff' },
          { id: 11, label: "Big Data Solutions", color: '#63d3ff' },
          { id: 12, label: "Machine Learning Solutions", color: '#58c6ff' },
          { id: 13, label: "E-Commerce Solutions", color: '#4dbaff' },
          { id: 14, label: "Custom CRM Development", color: '#42adff' },
          { id: 15, label: "Custom ERP Development", color: '#37a1f4' },
          { id: 16, label: "Business Intelligence Solutions", color: '#2c94e7' },
          { id: 17, label: "Legacy System Modernization", color: '#2188da' },
          { id: 18, label: "AR/VR Application Development", color: '#167acc' },
          { id: 19, label: "Chatbot Development", color: '#0b6ebf' },
          { id: 20, label: "Custom CMS Development", color: '#0060a0' },
        ],
      },
      { title: 'Offshore Development',
        subheading: "Streamlining Solutions Through Offshore Development Excellence",
        title1: 'odc-offshore-development-centre',
        homeTitle: 'Maximize Efficiency and Innovation with Our Offshore Development Center',
        introPage: 'Efficiency and Innovation Are',
        introPage1: 'Maximized',
        btnText: 'Read Now',
        img: Home1,
        imgSrc: CustomSoftware,
        description: "At skyniche Technologies, our Offshore Development Center (ODC) is designed to provide you with dedicated, cost-effective, and high-quality software development resources. Our ODC model ensures seamless collaboration, enhanced productivity, and access to top-tier talent to meet your business objectives. By leveraging our offshore capabilities, you can accelerate your development process, reduce operational costs, and focus on core business activities while we handle the technical complexities. Our team of skilled professionals works as an extension of your in-house team, delivering innovative solutions and ensuring your projects are completed on time and within budget.",
        description1: "Our Offshore Development Center is your gateway to innovative, scalable, and efficient software solutions. Partner with skyniche Technologies to leverage our expertise and drive your business forward.",
        focusHeading: 'List of ODC Services : ',
        focuspoints: [
          { id: 1, label: "Custom Software Development", color: '#0060a0' },
          { id: 2, label: "Web Application Development", color: '#0b6ebf' },
          { id: 3, label: "Mobile App Development", color: '#167acc' },
          { id: 4, label: "SaaS Application Development", color: '#2188da' },
          { id: 5, label: "Cloud Application Development", color: '#2c94e7' },
          { id: 6, label: "IoT App Development", color: '#37a1f4' },
          { id: 7, label: "AI and Machine Learning Solutions", color: '#42adff' },
          { id: 8, label: "Blockchain Development", color: '#4dbaff' },
          { id: 9, label: "Enterprise Software Solutions", color: '#58c6ff' },
          { id: 10, label: "Custom API Development", color: '#63d3ff' },
          { id: 11, label: "E-Commerce Solutions", color: '#63d3ff' },
          { id: 12, label: "Custom CRM Development", color: '#58c6ff' },
          { id: 13, label: "Custom ERP Development", color: '#4dbaff' },
          { id: 14, label: "Business Intelligence Solutions", color: '#42adff' },
          { id: 15, label: "Legacy System Modernization", color: '#37a1f4' },
          { id: 16, label: "AR/VR Application Development", color: '#2c94e7' },
          { id: 17, label: "Chatbot Development", color: '#2188da' },
          { id: 18, label: "Robotic Process Automation (RPA)", color: '#167acc' },
          { id: 19, label: "Quality Assurance and Testing", color: '#0b6ebf' },
          { id: 20, label: "IT Support and Maintenance", color: '#0060a0' },
        ],
      },
      { title: 'Cloud Solutions',
        subheading: "Harnessing the Power of Cloud Innovation for Growth and Success",
        title1: 'cloud-solutions',
        homeTitle: 'Elevate Your Business with <br/>Cutting-Edge Cloud Solutions',
        imgSrc: Cloud,
        description: "At skyniche Technologies, we specialize in developing cloud-compatible applications that leverage the full potential of modern cloud platforms. Our cloud solutions are designed to enhance scalability, security, and performance while ensuring seamless integration and deployment. By adhering to best practices in cloud development, including DevOps methodologies, we deliver robust and efficient solutions that meet your business needs. Whether you're looking to migrate to the cloud, optimize your existing infrastructure, or develop new cloud-native applications, our team of experts is here to guide you every step of the way.",
        description1: "With skyniche Technologies, you can transform your business operations and stay ahead of the competition by harnessing the power of the cloud. Partner with us to unlock new opportunities for innovation and growth.",
        focusHeading: 'List of Cloud Solutions Services : ',
        focuspoints: [
          { id: 1, label: "Cloud Application Development", color: '#0060a0' },
          { id: 2, label: "Cloud Migration Services", color: '#0b6ebf' },
          { id: 3, label: "Cloud Infrastructure Management", color: '#167acc' },
          { id: 4, label: "Cloud Security Solutions", color: '#2188da' },
          { id: 5, label: "DevOps Implementation", color: '#2c94e7' },
          { id: 6, label: "Continuous Integration and Continuous Deployment (CI/CD)", color: '#37a1f4' },
          { id: 7, label: "Cloud-Native Application Development", color: '#42adff' },
          { id: 8, label: "Microservices Architecture", color: '#4dbaff' },
          { id: 9, label: "Serverless Computing Solutions", color: '#58c6ff' },
          { id: 10, label: "Containerization (Docker, Kubernetes)", color: '#63d3ff' },
          { id: 11, label: "Cloud Cost Optimization", color: '#63d3ff' },
          { id: 12, label: "Multi-Cloud Strategy and Management", color: '#58c6ff' },
          { id: 13, label: "Hybrid Cloud Solutions", color: '#4dbaff' },
          { id: 14, label: "Disaster Recovery and Backup Solutions", color: '#42adff' },
          { id: 15, label: "Cloud Monitoring and Performance Optimization", color: '#37a1f4' },
          { id: 16, label: "API Management and Integration", color: '#2c94e7' },
          { id: 17, label: "Big Data and Analytics on Cloud", color: '#2188da' },
          { id: 18, label: "Artificial Intelligence and Machine Learning on Cloud", color: '#167acc' },
          { id: 19, label: "Cloud Consulting Services", color: '#0b6ebf' },
          { id: 20, label: "Cloud Training and Support", color: '#0060a0' },
        ], },
      { title: 'Consulting ',
        subheading: "Unlocking Potential: Expert Consulting for Business Transformation",
        title1: 'consulting ',
        homeTitle: 'Transform Your Business with Expert Consulting Services',
        introPage: 'Business Transformation Through',
        introPage1: 'Consulting',
        img: Home4,
        btnText: 'Get Start Now',
        imgSrc: Consulting,
        description: "At skyniche Technologies, our consulting services are designed to help businesses navigate the complexities of modern technology and achieve their strategic goals. Our team of seasoned consultants brings deep industry knowledge and technical expertise to deliver tailored solutions that drive efficiency, innovation, and growth. Whether you need guidance on digital transformation, IT strategy, or optimizing your operations, we offer a comprehensive range of consulting services to meet your unique needs. Partner with us to unlock your business's full potential and stay ahead in a rapidly evolving market.",
        description1: "With skyniche Technologies, you gain a trusted partner dedicated to helping you overcome challenges and seize opportunities. Let us guide you through your most critical initiatives and ensure your business is positioned for long-term success.",
        focusHeading: 'List of Consulting Services : ',
        focuspoints: [
          { id: 1, label: "Digital Transformation Consulting", color: '#0060a0' },
          { id: 2, label: "IT Strategy and Planning", color: '#0b6ebf' },
          { id: 3, label: "Business Process Optimization", color: '#167acc' },
          { id: 4, label: "Cloud Strategy and Consulting", color: '#2188da' },
          { id: 5, label: "Cybersecurity Consulting", color: '#2c94e7' },
          { id: 6, label: "Data Analytics and Business Intelligence", color: '#37a1f4' },
          { id: 7, label: "Artificial Intelligence and Machine Learning Consulting", color: '#42adff' },
          { id: 8, label: "Enterprise Architecture Consulting", color: '#4dbaff' },
          { id: 9, label: "DevOps and Agile Transformation", color: '#58c6ff' },
          { id: 10, label: "Software Development Lifecycle Consulting", color: '#63d3ff' },
          { id: 11, label: "Project Management and PMO Services", color: '#63d3ff' },
          { id: 12, label: "Change Management Consulting", color: '#58c6ff' },
          { id: 13, label: "Technology Assessment and Roadmapping", color: '#4dbaff' },
          { id: 14, label: "Vendor and Technology Selection", color: '#42adff' },
          { id: 15, label: "Risk Management and Compliance Consulting", color: '#37a1f4' },
          { id: 16, label: "IoT and Industry 4.0 Consulting", color: '#2c94e7' },
          { id: 17, label: "Blockchain and Distributed Ledger Consulting", color: '#2188da' },
          { id: 18, label: "Customer Experience and CRM Consulting", color: '#167acc' },
          { id: 19, label: "ERP Implementation and Optimization", color: '#0b6ebf' },
          { id: 20, label: "Training and Skill Development", color: '#0060a0' },
        ], },
      { title: 'Internet of things',
        subheading: "Seamless Integration for a Connected Future",
        title1: 'internet-of-things',
        homeTitle: 'Unlock the Power<br/>of Connectivity with IoT Solutions',
        imgSrc: Internet,
        introPage: 'IoT Solutions Unlock with',
        introPage1: 'skyniche',
        btnText: 'Check Now',
        img: Home3,
        description: "At skyniche Technologies, we provide comprehensive Internet of Things (IoT) solutions designed to transform your business through enhanced connectivity and data-driven insights. Our IoT services enable you to connect, monitor, and manage your devices seamlessly, providing real-time analytics and automation to optimize your operations. By leveraging the latest in IoT technology, we help you unlock new opportunities for innovation, improve efficiency, and deliver superior customer experiences. Whether you’re looking to implement smart solutions in manufacturing, healthcare, retail, or any other industry, our team of experts is here to support you at every step.",
        description1: "With skyniche Technologies, you can harness the full potential of IoT to drive innovation and operational excellence. Partner with us to create connected ecosystems that bring tangible value to your business and customers.",
        focusHeading: 'List of IoT Services : ',
        focuspoints: [
          { id: 1, label: "IoT Strategy and Consulting", color: '#0060a0' },
          { id: 2, label: "IoT Device Development", color: '#0b6ebf' },
          { id: 3, label: "IoT Platform Integration", color: '#167acc' },
          { id: 4, label: "Sensor and Actuator Integration", color: '#2188da' },
          { id: 5, label: "IoT Data Analytics", color: '#2c94e7' },
          { id: 6, label: "Edge Computing Solutions", color: '#37a1f4' },
          { id: 7, label: "Industrial IoT (IIoT) Solutions", color: '#42adff' },
          { id: 8, label: "Smart Home and Building Solutions", color: '#4dbaff' },
          { id: 9, label: "Connected Healthcare Solutions", color: '#58c6ff' },
          { id: 10, label: "Smart Retail Solutions", color: '#63d3ff' },
          { id: 11, label: "IoT Security Solutions", color: '#63d3ff' },
          { id: 12, label: "IoT Network Management", color: '#58c6ff' },
          { id: 13, label: "Predictive Maintenance Solutions", color: '#4dbaff' },
          { id: 14, label: "Fleet Management Solutions", color: '#42adff' },
          { id: 15, label: "IoT Application Development", color: '#37a1f4' },
          { id: 16, label: "Remote Monitoring and Control", color: '#2c94e7' },
          { id: 17, label: "IoT System Integration", color: '#2188da' },
          { id: 18, label: "Wearable Technology Solutions", color: '#167acc' },
          { id: 19, label: "Agriculture IoT Solutions", color: '#0b6ebf' },
          { id: 20, label: "IoT Training and Support", color: '#0060a0' },
        ], },
      { title: 'Experience Services',
        subheading: "Delivering Excellence: Elevating Customer Satisfaction through Exceptional Services",
        title1: 'experience-services',
        homeTitle: 'Enhance User Satisfaction with Exceptional Customer Experience Services',
        imgSrc: Experince,
        description: "At skyniche Technologies, we specialize in crafting superior customer experiences through our innovative software solutions. Our approach prioritizes user experience (UX) over user interface (UI), ensuring that your applications are intuitive, engaging, and designed to meet the needs of your customers. By focusing on simplicity and functionality, we create seamless and enjoyable interactions that drive user satisfaction and loyalty. Whether you’re developing a new application or optimizing an existing one, our team of experts is dedicated to enhancing every aspect of your customer service experience.",
        description1: "With skyniche Technologies, you can deliver exceptional customer service experiences that set your software apart. Partner with us to create applications that not only meet but exceed your users' expectations, fostering long-term engagement and satisfaction.",
        focusHeading: 'List of Customer Experience Services : ',
        focuspoints: [
          { id: 1, label: "UX Research and Analysis", color: '#0060a0' },
          { id: 2, label: "User-Centered Design", color: '#0b6ebf' },
          { id: 3, label: "Interaction Design", color: '#167acc' },
          { id: 4, label: "Usability Testing and Evaluation", color: '#2188da' },
          { id: 5, label: "Prototyping and Wireframing", color: '#2c94e7' },
          { id: 6, label: "Information Architecture", color: '#37a1f4' },
          { id: 7, label: "Accessibility Consulting", color: '#42adff' },
          { id: 8, label: "Responsive Design", color: '#4dbaff' },
          { id: 9, label: "Mobile User Experience Optimization", color: '#58c6ff' },
          { id: 10, label: "Customer Journey Mapping", color: '#63d3ff' },
          { id: 11, label: "User Persona Development", color: '#63d3ff' },
          { id: 12, label: "UI/UX Audits", color: '#58c6ff' },
          { id: 13, label: "Visual Design Services", color: '#4dbaff' },
          { id: 14, label: "Content Strategy and Design", color: '#42adff' },
          { id: 15, label: "Microinteraction Design", color: '#37a1f4' },
          { id: 16, label: "Performance Optimization", color: '#2c94e7' },
          { id: 17, label: "User Feedback and Iteration", color: '#2188da' },
          { id: 18, label: "A/B Testing and Analytics", color: '#167acc' },
          { id: 19, label: "Continuous UX Improvement", color: '#0b6ebf' },
          { id: 20, label: "UX Training and Workshops", color: '#0060a0' },
        ], },
    ]
  },
  // {
  //   headline: 'Insights',
  //   children: [
  //     { title: 'Modern Business', href: '#' },
  //     { title: 'Deep Green', href: '#' },
  //     { title: 'Blog', href: '/news' },
  //     { title: 'Case Studies', href: 'case-studies' },
  //   ]
  // },
  {
    headline: 'About',
    children: [
      { title: 'Contact Us', href: '/contact-us' },
      { title: 'News & Blogs', href: '/news' },
      // { title: 'Partnerships', href: '#' },
      { title: 'Case Studies', href: '/case-studies' },
      { title: 'Vision And Standards', href: '/vision-and-standards' },
      { title: 'About skyniche', href: '/about' },
      { title: 'Events', href: '/events' },
    ]
  },
  {
    headline: 'Solutions',
    children: [
      {
        title: 'Seed to Sale CRM',
        subheading: "Your Comprehensive Solution for Tracking and Enhancing Business Operations",
        title1: 'seed-to-sale-crm',
        homeTitle: 'Seed to Sale CRM Grow Your Business',
        imgSrc: SeedToCRM,
        description: "skyniche and Tridant LLC aims to lead the logistical management of the legal cannabis industry, with Tridant LLC innovative Seed to Sale Software Suite (4S), an industry services software product. Motagistic 4S is a turnkey e-commerce industrial software, the architecture of which tailors to this still budding, quasi-regulated State legal cannabis market. While also addressing the unrealized needs of State legal cannabis markets, Tridant LLC brings this next generation of logistical software to this quickly evolving industry by partnering with the State regulatory agencies. This partnership enables the agency to affordably and effectively manage legislation through our turn-key Point-of-Sale software that offers next generation e-management, e-process, e-finance, e-advertising and e-marketing business functionality to all legal cannabis regulators and vendors.",
        description1: "Our Seed to Sale CRM suite is designed to help you efficiently track and manage every aspect of your business. From cultivation and production to inventory and sales, our comprehensive tools ensure you have complete visibility and control over your operations. Streamline your workflows, enhance productivity, and make informed decisions with real-time data insights. Whether you're a small startup or an established enterprise, our CRM suite scales with your business needs, providing robust features such as customer relationship management, compliance tracking, and detailed reporting. Experience seamless integration and support as you grow your business and achieve new levels of success with our Seed to Sale CRM suite.",
        focusHeading: 'Tridant LLC: Pioneering Automation in the Legal Cannabis Industry',
        focuspoints : [
          { id: 1, label: "Regulatory Oversight / Control", color: '#0060a0' },
          { id: 2, label: "Law enforcement", color: '#0b6ebf' },
          { id: 3, label: "Physicians & Patients", color: '#167acc' },
          { id: 4, label: "Growing", color: '#2188da' },
          { id: 5, label: "Testing", color: '#2c94e7' },
          { id: 6, label: "Processing", color: '#37a1f4' },
          { id: 7, label: "Manufacturing", color: '#42adff' },
          { id: 8, label: "Inventory Management", color: '#4dbaff' },
          { id: 9, label: "Transportation", color: '#58c6ff' },
          { id: 10, label: "Sale", color: '#63d3ff' },
          { id: 11, label: "Research", color: '#63d3ff' },
          { id: 12, label: "Substance Abuse Counseling", color: '#58c6ff' },
        ],
      },
      {
        title: 'Restaurant POS Systems',
        subheading: "Streamline Operations and Enhance Efficiency with Advanced POS Solutions",
        title1: 'restaurant-pos-systems',
        homeTitle: 'Restaurant POS Systems: Make Your Restaurant Automated',
        imgSrc: ResturentPOS,
        description: "Introducing the new generation flexible, robust and secure restaurant POS systems. Increase your sales with our menu app and go paperless. Our backend helps you to expand the menu connected to your digital board and POS. Our efficient kitchen application smooths your kitchen operations.<br/><br/>If  you are looking to grow your business and become more efficient, our POS solutions are fast to deploy. It works on a desktop or any mobile device.",
        description1: "Transform your restaurant operations with our state-of-the-art Restaurant POS suite. Our comprehensive system is designed to automate various aspects of your restaurant, from order taking and payment processing to inventory management and customer relationship management. Streamline your workflows, reduce errors, and enhance the overall dining experience for your customers. With features like real-time sales tracking, detailed reporting, and seamless integration with other business tools, our POS system provides the insights and efficiency needed to drive growth and profitability. Whether you run a small café or a large dining establishment, our Restaurant POS suite scales to meet your specific needs, helping you focus on what matters most – delivering exceptional service to your guests.",
        focusHeading: 'Restaurant POS Software Features',
        focuspoints : [
          { id: 1, label: "Inventory Management", color: '#0060a0' },
          { id: 2, label: "Customized menu", color: '#0b6ebf' },
          { id: 3, label: "Loyalty & Discount Management", color: '#167acc' },
          { id: 4, label: "Staff management", color: '#2188da' },
          { id: 5, label: "Third party software integration", color: '#2c94e7' },
          { id: 6, label: "Real-Time Sales Tracking", color: '#37a1f4' },
        ],
      },
      {
        title: 'RFID POS Systems',
        subheading: "Enhance Efficiency and Accuracy with Advanced RFID Technology",
        title1: 'rfid-pos-system',
        homeTitle: 'RFID POS System: Make Your Retail Business Automated',
        imgSrc: RFIDPOS,
        description: "Introducing the new generation flexible, robust and secure RFID POS system. Using the RFID technology the time of people in queues will be reduced to 1/3rd of the total time taken to stand in the queues. People can place all their products on the plain area where an RFID reader will be placed which will be scanning all the items having RFID tags simultaneously and automatically updates the details of each item into the computer. Thus, a single person is enough at the billing section just to print out the bill and hand it over to the customer. Thus, reduces the overall waiting time of the customers and enable a better shopping environment for the customers.<br/><br/>If you are looking to grow your business and become more efficient, our POS solutions are fast to deploy. It works on a desktop or any mobile device",
        description1: "Revolutionize your retail operations with our cutting-edge RFID POS system. Designed to automate and streamline various aspects of your business, our system leverages RFID technology to provide faster and more accurate transactions. This advanced system not only speeds up the checkout process but also improves inventory management by offering real-time tracking and instant updates. Reduce human errors, prevent theft, and ensure your stock levels are always accurate. Our RFID POS system integrates seamlessly with your existing infrastructure, offering robust data analytics and reporting tools to help you make informed business decisions. Whether you run a small boutique or a large retail chain, our RFID POS system is scalable to meet your unique needs, driving efficiency, and enhancing the overall customer experience.",
        focusHeading: 'RFID POS Software Features',
        focuspoints : [
          { id: 1, label: "Product registration with RFID tags", color: '#0060a0' },
          { id: 2, label: "Tracking of returnable assets", color: '#0b6ebf' },
          { id: 3, label: "Purchase Order Management", color: '#167acc' },
          { id: 4, label: "Supports both passive and active RFID tags", color: '#2188da' },
          { id: 5, label: "Quick scanning of multiple items with minimal effort", color: '#2c94e7' },
          { id: 6, label: "Compatible with fixed and mobile handheld RFID scanners", color: '#37a1f4' },
          { id: 7, label: "Anti-Theft System via Service", color: '#42adff' },
          { id: 8, label: "Inventory Management", color: '#4dbaff' },
          { id: 9, label: "Order Management", color: '#58c6ff' },
          { id: 10, label: "Incoming Order", color: '#63d3ff' },
          { id: 11, label: "ScreenKitchen Management", color: '#63d3ff' },
          { id: 12, label: "Customized menu", color: '#58c6ff' },
          { id: 13, label: "Expenses Management", color: '#4dbaff' },
          { id: 14, label: "Staff management", color: '#42adff' },
          { id: 15, label: "Customizable Reports", color: '#37a1f4' },
          { id: 16, label: "Loyalty & Discount Management", color: '#2c94e7' },
          { id: 17, label: "Third party software integration", color: '#2188da' },
          { id: 18, label: "Real-Time Sales Tracking", color: '#167acc' },
        ],
      },
      {
        title: 'Cloud Call Center Software',
        subheading: "Why cloud call center software?",
        title1: 'call-center-software',
        homeTitle: 'Cloud Call Center Software: Build a Better Customer Experience',
        imgSrc: CallCenter,
        description: "The COVID-19 pandemic has affected lives and company’s around the world, and the call center industry is no exception. skyniche and WhiteHat LLC built a solution which can be used remotely, where work from home agents can seamlessly supplement companies current workforce to provide a personalized and effortless experience to your customers virtually. We are proud to help the support centers of all the businesses providing the additional benefits of the flexibility gained through the use of remote workers with our cloud based virtual call center solution.",
        description1: "Elevate your customer service with our advanced cloud call center software. Designed to streamline operations and enhance communication, our software offers a range of features tailored to meet the needs of modern businesses. Enjoy seamless integration with your existing systems, robust data analytics, and real-time reporting to monitor performance and make informed decisions. Our cloud-based solution ensures scalability and flexibility, allowing you to easily manage call volumes and adapt to changing demands. With intuitive interfaces and automated workflows, your team can focus on delivering exceptional customer experiences. Boost satisfaction, improve efficiency, and drive business growth with our cutting-edge cloud call center software.",
        focusHeading: 'Call center Features',
        focuspoints : [
          { id: 1, label: "Interactive Voice Response(IVR)", color: '#0060a0' },
          { id: 2, label: "Call Recording", color: '#0b6ebf' },
          { id: 3, label: "Toll-Free Phone Numbers", color: '#167acc' },
          { id: 4, label: "Call Presence(Agent Status)", color: '#2188da' },
          { id: 5, label: "Call Queue", color: '#2c94e7' },
          { id: 6, label: "Call Analytics(Live & Historical)", color: '#37a1f4' },
          { id: 7, label: "Transfer Calls with One Click", color: '#42adff' },
          { id: 8, label: "Conference Calling", color: '#4dbaff' },
          { id: 9, label: "Staff Management", color: '#58c6ff' },
          { id: 10, label: "Three-Way Calling", color: '#63d3ff' },
          { id: 11, label: "Call Monitoring", color: '#63d3ff' },
          { id: 12, label: "Automatic Call Distribution (ACD)", color: '#58c6ff' },
        ],
      },
    ]
  },
  {
    headline: 'Asia & Europe',
    children: [
      { title: 'India', },
      { title: 'Germany', },
      { title: 'Nepal', },
      { title: 'UK', },
    ]
  },
  {
    headline: 'Americas',
    children: [
      { title: 'Argentina', },
      { title: 'USA', },
      { title: 'Jamaica', },
      { title: 'Colombia', },
    ]
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

const APP_BAR_DESKTOP = 88;
const APP_BAR_DESKTOP_SMALL = 0;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_DESKTOP,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  },
}));

const ToolbarStyle1 = styled(Toolbar)(({ theme }) => ({
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP_SMALL
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  top: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const navigate = useNavigate();

  const handleNavigation = (item, title) => {
    if (title === 'Services') {
      navigate(`/services/${item.title1}`, { state: item });
    } else if (title === 'Industries') {
      navigate(`/industry/${item.title1}`, { state: item });
    } else if (title === 'Solutions') {
      navigate(`/solutions/${item.title1}`, { state: item });
    } else {
      title !== 'Asia & Europe' && title !== 'Americas' && navigate(item.href);
    }
  };

  return (
    <>
      <Helmet>
        <title>Leader in custom software development, IT services, and solutions.</title>
        <meta name="description" content="At skyniche Technologies, we offer a range of services including Custom Software Development, Offshore Development, Cloud Solutions, Consulting, IoT Solutions, and Customer Experience Services to help businesses transform and stay ahead in a rapidly evolving market." />
      </Helmet>
      <RootStyle sx={{ pl: { xs: 2, sm: 2, md: 6 }, pr: { xs: 2, sm: 2, md: 6 } }}>
        <Divider />
        <Box maxWidth="lg" sx={{ pt: 3, pb: 3, pl: { xs: 1, sm: 1, md: 6 }, pr: { xs: 1, sm: 1, md: 6 } }}>
          <Grid
            container
            justifyContent="space-between"
            sx={{ textAlign: { xs: 'left', md: 'left' } }}
            spacing={4}
          >
            {LINKS.map((list) => {
              const { headline, children } = list;
              return (
                <Grid item xs={12} md={4} key={headline}>
                  <Stack>
                    <Typography
                      component="h2"
                      sx={{
                        textAlign: 'left',
                        fontSize: '20px',
                        fontWeight: '600',
                        color: '#000048',
                        fontFamily: '"Gellix",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
                      }}
                    >
                      {headline}
                    </Typography>
                    <Grid container sx={{ mt: 0.5 }}>
                      {children.map((link, index) => (
                        <Grid item xs={12} sm={6} sx={{ mt: 1 }} md={6} key={index}>
                          <Link
                            onClick={() => handleNavigation(link, headline)}
                            key={link.title}
                            color="inherit"
                            underline="none"
                            component="button"
                            sx={{
                              display: 'block',
                              cursor: 'pointer',
                              textAlign: 'left',
                              fontSize: '15px',
                              fontWeight: '400',
                              color: '#000048',
                              mr: 0.1,
                              '&:hover': {
                                color: '#027bc2'
                              },
                              fontFamily: '"Gellix",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
                            }}
                          >
                            {link.title}
                          </Link>
                        </Grid>
                      ))}
                    </Grid>
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </RootStyle>
      <RootStyle sx={{ boxShadow: 0, height: { xs: '200px', sm: '200px', md: '65px' }, pt: { xs: 1, sm: 1, md: 0 }, backgroundColor: '#e8e8e6' }}>
        <ToolbarStyle1 disableGutters>
          <Container
            maxWidth="lg"
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '10px',
              justifyContent: 'flex-end'
            }}
          >
            <MHidden width="xsDown">
              <FooterMenuNew />
            </MHidden>
          </Container>
        </ToolbarStyle1>
      </RootStyle>
    </>
  );
}
