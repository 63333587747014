import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import googleFill from '@iconify/icons-eva/google-fill';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Link, Grid, List, Stack, ListItem, ListSubheader, Avatar, Divider, IconButton } from '@material-ui/core';
import { Circle, Language } from '@material-ui/icons';

// ----------------------------------------------------------------------

function getCurrentYear() {
    return new Date().getFullYear();
  }

const LinkStyle = styled(Link)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  marginRight: theme.spacing(2),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shortest
  }),
  '&:hover': {
    opacity: 0.48,
    textDecoration: 'none'
  }
}));

// ----------------------------------------------------------------------

function IconBullet({ type = 'item' }) {
  return (
    <Box sx={{ width: 24, height: 16, display: 'flex', alignItems: 'center' }}>
      <Box
        component="span"
        sx={{
          ml: '2px',
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'currentColor',
          ...(type !== 'item' && { ml: 0, width: 8, height: 2, borderRadius: 2 })
        }}
      />
    </Box>
  );
}

IconBullet.propTypes = {
  type: PropTypes.oneOf(['subheader', 'item'])
};

const SOCIALS = [
    { name: 'FaceBook', icon: 'ri--facebook-fill' ,path:'https://www.facebook.com/skyniche/'},
    { name: 'Youtube', icon: 'bi--youtube',path:'https://www.youtube.com/channel/UC6zvvBK_ZCh8vg_VeyoQn7w ' },
    { name: 'Linkedin', icon: 'ri--linkedin-fill',path:'https://in.linkedin.com/company/skyniche ' },
    { name: 'Twitter', icon: 'line-md--twitter-x',path:'https://x.com/SkyNiche?mx=2' },
    { name: 'Instagram', icon: 'bi--instagram',path:'https://www.instagram.com/skyniche_official/' }
  ];

function MenuDesktopItem({ item, pathname, isHome, isOffset }) {
  const { title, path, children } = item;
  const isActive = pathname === path;
  

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left',pl:{xs:1,sm:1,md:0} }}>
      <LinkStyle
        key={title}
        to={title !==`© ${getCurrentYear()}. All rights reserved` && path}
        component={RouterLink}
        sx={{cursor:'pointer',textAlign:'left',fontSize:'15px',fontWeight:'600',color: '#000048',fontFamily: '"Gellix",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',mt:0.5 }}
        // sx={{
        //   display: 'flex',
        //   cursor: 'pointer',
        //   alignItems: 'center',
        //   ...(isHome && { color: 'text.primary' }),
        //   ...(isOffset && { color: 'text.primary' }),
        // }}
      >
        {title}
      </LinkStyle>
    </Box>
  );
}

MenuDesktopItem.propTypes = {
  item: PropTypes.object,
  pathname: PropTypes.string,
  isHome: PropTypes.bool,
  isOffset: PropTypes.bool
};

export default function FooterMenuNew({ isOffset, isHome }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const navItems = [
    { title: 'Sitemap', path: '/site-map' },
    { title: 'Terms', path: '/terms-conditions', iconBullet: true },
    { title: 'Privacy Notice', path: '/privacy-policy', iconBullet: true },
    { title: `© ${getCurrentYear()}. All rights reserved`, iconBullet: true },
  ];

  return (
    <Grid container direction={{ xs: 'column', md: 'row' }} sx={{textAlign:'left'}} justifyContent="space-between" alignItems="flex-start">
      <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent:'flex-start', mb: { xs: 2, md: 0 } }}>
        <Stack
          spacing={1.5}
          direction="row"
          justifyContent={{ xs: 'center', md: 'flex-start' }}
        >
          {SOCIALS.map((social) => (
            <IconButton aria-label="Social Media Links" onClick={() => window.open(social.path, '_blank')} key={social.name} color="primary" sx={{ p: 1 }}>
              <Stack className={social.icon} sx={{ fontSize: '20px' }} />
            </IconButton>
          ))}
        </Stack>
      </Grid>
      <Grid item xs={12} md={9} sx={{ display: 'flex', justifyContent: {xs:'flex-start',md:'flex-end'},textAlign:'left' }}>
        <Stack direction={{ xs: 'column', md: 'row' }} sx={{textAlign:'left'}}>
          {navItems.map((link) => (
            <MenuDesktopItem
              key={link.title}
              item={link}
              pathname={pathname}
              isOffset={isOffset}
              isHome={isHome}
            />
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
}
