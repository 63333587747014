import PropTypes from 'prop-types';
import { useState } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Link, Grid, List, Stack, Popover, ListItem, ListSubheader } from '@material-ui/core';
import { Icon } from '@iconify/react';
import arrowIosUpwardFill from '@iconify/icons-eva/arrow-ios-upward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';

const LinkStyle = styled(Link)(({ theme }) => ({
  color: '#000049',
  fontSize: '20px',
  fontWeight: '450',
  fontFamily: '"Gellix",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  padding: theme.spacing(1.5),
  // marginRight: theme.spacing(2),
  borderRadius:'3px',
  // transition: theme.transitions.create(['color', 'background-color'], {
  //   duration: theme.transitions.duration.shortest
  // }),
  transition: 'color 0.5s, background-color 0.5s',
  '&:hover': {
    backgroundColor: '#027dc2',
    color: '#ffffff',
    textDecoration:'none',
    underline:'none',
    transition: 'color 0.5s, background-color 0.5s',
  }
}));

const IconBullet = ({ type = 'item' }) => (
  <Box sx={{ width: 24, height: 16, display: 'flex', alignItems: 'center' }}>
    <Box
      component="span"
      sx={{
        ml: '2px',
        width: type === 'item' ? 4 : 8,
        height: type === 'item' ? 4 : 2,
        borderRadius: type === 'item' ? '50%' : 2,
        bgcolor: 'currentColor'
      }}
    />
  </Box>
);

IconBullet.propTypes = {
  type: PropTypes.oneOf(['subheader', 'item'])
};

const MenuDesktopItem = ({ item, pathname, isHome, isOpen, isOffset, onOpen, onClose }) => {
  const { title, path, children } = item;
  const isActive = pathname === path;

  const handleItemClick = () => {
    if (!isOpen) {
      onOpen();
    } else {
      onClose();
    }
  };

  return (
    <div key={title}>
      {children ? (
        <>
          <LinkStyle
            onClick={handleItemClick}
            component="div"
            sx={{
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'center',
              color: isHome || isOffset ? '#000049' : undefined,
              opacity: isOpen ? 0.48 : undefined,
              '&:hover': {
                color: '#ffffff',
                '.bx--down-arrow': {
                  color: '#ffffff',
                  transition: 'color 0.5s, background-color 0.5s', 
                }
              }
            }}
          >
            {title}
            <Stack className='bx--down-arrow' sx={{ ml: 0.5, fontSize: '1.5rem', mt: 0.4, color: '#6aa2dc' }} />
          </LinkStyle>

          <Popover
            open={isOpen}
            // anchorReference="anchorPosition"
            // anchorPosition={{ top: 80, left: 0 }}
            anchorOrigin={{
              vertical: 330,
              horizontal: 850
            }}
            transformOrigin={{
              vertical: 200,
              horizontal: 200
            }}
            onClose={onClose}
            PaperProps={{
              sx: {
                px: 3,
                pt: 3,
                pb: 3,
                right: 20,
                margin: 'auto',
                borderRadius: 2,
                // width:'fit-content', 
                maxWidth: '600px',
                whiteSpace:'nowrap',
                display: 'flex',
              }
            }}
          >
            <Grid container spacing={4} sx={{display:'flex',justifyContent:'space-between'}}>
              {children.map((list) => (
                <Grid key={list.subheader} item xs={4}>
                  <List disablePadding>
                    <ListSubheader
                      disableSticky
                      disableGutters
                      sx={{
                        display: 'flex',
                        lineHeight: 'unset',
                        alignItems: 'center',
                        color: 'text.primary',
                        typography: 'overline',
                        mt: -1
                      }}
                    >
                      <IconBullet type="subheader" /> {list.subheader}
                    </ListSubheader>

                    {list.items.map((item) => (
                      <ListItem
                        key={item.title}
                        to={item.path}
                        component={RouterLink}
                        underline="none"
                        sx={{
                          p: 0,
                          mt: 3,
                          typography: 'body2',
                          color: 'text.secondary',
                          transition: 'color 0.3s',
                          '&:hover': { color: 'text.primary' }
                        }}
                        onClick={onClose} // Close Popover on submenu item click
                      >
                        <>
                          <IconBullet />
                          {item.title}
                        </>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              ))}
            </Grid>
          </Popover>
        </>
      ) : (
        <LinkStyle
          to={path}
          component={RouterLink}
          sx={{
            ...(isActive && { color: 'primary.main', backgroundColor: '#6aa2dc', '&:hover': { color: '#ffffff' } }),
            ...(isHome && { color: '#000049' }),
            ...(isOffset && { color: '#000049' })
          }}
        >
          {title}
        </LinkStyle>
      )}
    </div>
  );
};

MenuDesktopItem.propTypes = {
  item: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  isHome: PropTypes.bool,
  isOffset: PropTypes.bool,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

const MenuDesktop = ({ isOffset, isHome, navConfig }) => {
  const { pathname } = useLocation();
  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (title) => {
    setOpenPopover(openPopover === title ? null : title);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <Stack direction="row">
      {navConfig.map((link) => (
        <MenuDesktopItem
          key={link.title}
          item={link}
          pathname={pathname}
          isOpen={openPopover === link.title}
          onOpen={() => handleOpenPopover(link.title)}
          onClose={handleClosePopover}
          isOffset={isOffset}
          isHome={isHome}
        />
      ))}
    </Stack>
  );
};

MenuDesktop.propTypes = {
  isOffset: PropTypes.bool,
  isHome: PropTypes.bool,
  navConfig: PropTypes.array.isRequired
};

export default MenuDesktop;
