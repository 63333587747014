import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Link, Grid, List, Stack, Popover, ListItem, ListSubheader, Avatar, Divider, Typography, Menu } from '@material-ui/core';
import { Circle, Language } from '@material-ui/icons';
import Argentina from '../../images/argentina.png';
import Bulgeria from '../../images/bulgeria.png';
import Germany from '../../images/germany.png';
import India from '../../images/india.png';
import Jamaica from '../../images/jamaica.png';
import Kenya from '../../images/kenya.png';
import Nigeria from '../../images/nigeria.png';
import Nepal from '../../images/nepal.png';
import UK from '../../images/uk.png';
import Zambia from '../../images/zambia.png';
import USA from '../../images/usa.png';
import UAE from '../../images/uae.png';
import Australia from '../../images/australia.png';
import Colombia from '../../images/colombia.png';
import Guatemala from '../../images/guatemala.png';
import Swedan from '../../images/swedan.png';
import Singapore from '../../images/singapore.png';
import Chile from '../../images/chile.png';
import Iran from '../../images/iran.png';
import Ghana from '../../images/ghana.png';

// ----------------------------------------------------------------------

const LinkStyle = styled(Link)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  marginRight: theme.spacing(2),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shortest
  }),
  '&:hover': {
    opacity: 0.48,
    textDecoration: 'none'
  }
}));

// ----------------------------------------------------------------------

function IconBullet({ type = 'item' }) {
  return (
    <Box sx={{ width: 24, height: 16, display: 'flex', alignItems: 'center' }}>
      <Box
        component="span"
        sx={{
          ml: '2px',
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'currentColor',
          ...(type !== 'item' && { ml: 0, width: 8, height: 2, borderRadius: 2 })
        }}
      />
    </Box>
  );
}

IconBullet.propTypes = {
  type: PropTypes.oneOf(['subheader', 'item'])
};

const countryData = [
  {
    continent: 'Asia',
    countries: [
      { name: 'India', flag: India },
      { name: 'Nepal', flag: Nepal },
      { name: 'UAE', flag: UAE },
      { name: 'Iran', flag: Iran },
      { name: 'Singapore', flag: Singapore },
      { name: 'Australia', flag: Australia },
    ]
  },

  {
    continent: 'Africa',
    countries: [
      { name: 'Nigeria', flag: Nigeria },
      { name: 'Kenya', flag: Kenya },
      { name: 'Ghana', flag: Ghana },
      { name: 'Zambia', flag: Zambia },
    ]
  },
  {
    continent: 'Europe',
    countries: [
      { name: 'Germany', flag: Germany },
      { name: 'UK', flag: UK },
      { name: 'Bulgeria', flag: Bulgeria },
      { name: 'Swedan', flag: Swedan },
    ]
  },
  {
    continent: 'Americas',
    countries: [
      { name: 'USA', flag: USA },
      { name: 'Argentina', flag: Argentina },
      { name: 'Jamaica', flag: Jamaica },
      { name: 'Colombia', flag: Colombia },
      { name: 'Guatemala', flag: Guatemala },
      { name: 'Chile', flag: Chile },
    ]
  },
];

function MenuDesktopItem({ item, pathname, isHome, isOpen, isOffset, onOpen, onClose }) {
  const { title, path, children,icon } = item;
  const isActive = pathname === path;

  if (children) {
    return (
      <div key={title}>
        <LinkStyle
          onClick={onOpen}
          sx={{
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            mt:1.2,
            ...(isHome && { color: 'text.primary' }),
            ...(isOffset && { color: 'text.primary' }),
            ...(isOpen && { opacity: 0.48 })
          }}
        >
          <Language sx={{fontSize:'1.2rem',mr:0.5,mt:-0.1}} />
          {title}
        </LinkStyle>
        <Menu
          keepMounted
          id="simple-menu"
          anchorEl={isOpen}
          onClose={onClose}
          open={Boolean(isOpen)}
          anchorOrigin={{
            vertical: -120,
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
        <Box sx={{ p: 2, maxWidth: 'fit-content' }}>
          <Grid container spacing={4} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {children.map((list) => {
              const { subheader, items } = list;
              return (
                <Grid key={subheader} item xs={3} pl={2} pr={2}>
                  <List disablePadding>
                    <ListSubheader
                      disableSticky
                      disableGutters
                      sx={{
                        display: 'flex',
                        lineHeight: 'unset',
                        alignItems: 'center',
                        color: 'text.primary',
                        typography: 'overline',
                        mt: -1
                      }}
                    >
                      <IconBullet type="subheader" /> {subheader}
                    </ListSubheader>
                    
                    {items.map((item) => (
                      <ListItem
                        key={item.name}
                        sx={{
                          p: 0,
                          mt: 3,
                          typography: 'body2',
                          color: 'text.secondary',
                          transition: (theme) => theme.transitions.create('color'),
                          '&:hover': { color: 'text.primary' }
                        }}
                      >
                        <Avatar
                          src={item.flag}
                          alt={item.name}
                          sx={{ mr: 1, border: '2px solid #e6e6e6' }}
                        />
                        {item.name}
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Menu>

        
      </div>
    );
  }

  return (
    <>
    {title === 'Careers' ? (
      <><LinkStyle
      key={title}
      to={path}
      component={RouterLink}
      sx={{
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        ...(isHome && { color: 'text.primary' }),
        ...(isOffset && { color: 'text.primary' }),
        ...(isOpen && { opacity: 0.48 })
      }}
    >
      {title}
    </LinkStyle>
    </>)
     : 
    <>
    {title === 'News' ?(<>
    <LinkStyle
      key={title}
      to={path}
      component={RouterLink}
      sx={{
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        ...(isHome && { color: 'text.primary' }),
        ...(isOffset && { color: 'text.primary' }),
        ...(isOpen && { opacity: 0.48 })
      }}
    >
      <Stack className='material-symbols-light--news' sx={{fontSize:'1.5rem',mr:0.6,ml:-0.3,mt:0}} />
      {title}
    </LinkStyle>
    
    </>
    ) 
    : (
    <>
    <LinkStyle
      key={title}
      to={path}
      component={RouterLink}
      sx={{
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        ...(isHome && { color: 'text.primary' }),
        ...(isOffset && { color: 'text.primary' }),
        ...(isOpen && { opacity: 0.48 })
      }}
    >
      <Stack className={icon} sx={{fontSize:'1.3rem',mr:0.6,ml:-0.5,mt:0}} />
      {title}
    </LinkStyle>
    <Divider orientation="vertical" sx={{ width: 2, height: 40,mr:2, }} />
    </>)
    }
    </>
    }
    </>
  );
}

MenuDesktopItem.propTypes = {
  item: PropTypes.object,
  pathname: PropTypes.string,
  isHome: PropTypes.bool,
  isOffset: PropTypes.bool,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func
};

export default function DesktopMenuNew({ isOffset, isHome }) {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };


  const handleClose = () => {
    setOpen(false);
  };

  const navItems = [
    { title: 'News', path: '/news' },
    { title: 'Events', path: '/events', iconBullet: true,icon:'mdi--events' },
    // { title: 'Events', path: '/', iconBullet: true },
    {
      title: 'Global Clientele',
      // path: '/worldwide',
      children: countryData.map((continent) => ({
        subheader: continent.continent,
        items: continent.countries
      }))
    }
  ];

  return (
    <Stack direction="row">
      {navItems.map((link) => (
        <MenuDesktopItem
          key={link.title}
          item={link}
          pathname={pathname}
          isOpen={open && link.title === 'Global Clientele'}
          onOpen={link.title === 'Global Clientele' ? handleOpen : undefined}
          onClose={link.title === 'Global Clientele' ? handleClose : undefined}
          isOffset={isOffset}
          isHome={isHome}
        />
      ))}
    </Stack>
  );
}
