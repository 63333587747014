import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Paper, Link, Typography, Stack } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { keyframes } from '@emotion/react';

const CONTENT_HEIGHT = 480;
const ITEM_SPACING = 4;
const ITEM_HEIGHT = 85;
const ITEM_ON_ROW = {
  width: 'calc((100%/3))',
  '&:nth-child(3n+1)': { order: 1 },
  '&:nth-child(3n+2)': { order: 2 },
  '&:nth-child(3n)': { order: 3 }
};

const iconFade = keyframes`
  from { opacity: 0; transform: scale(0.5); }
  to { opacity: 1; transform: scale(1); }
`;

const ParentItem = ({ title, hasSub, handleToggle, isActive }) => {
  return (
    <Link
      component="button"
      underline="none"
      sx={{
        color: '#000049',
        fontSize: '18px',
        fontWeight: '450',
        fontFamily: '"Gellix",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
        padding: '15px',
        transition: 'color 0.5s, background-color 0.5s',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: isActive ? '#027dc2' : 'transparent',
        color: isActive ? '#ffffff' : '#000049',
        '&:hover': {
          backgroundColor: '#027dc2',
          color: '#ffffff',
          textDecoration: 'none',
          '& .bx--down-arrow': {
            color: '#ffffff',
          },
        }
      }}
      onClick={handleToggle}
    >
      <span>{title}</span>
      {hasSub && (
        <Stack>
          {!isActive ? (
            <Stack
              sx={{
                ml: 0.5,
                fontSize: '1.5rem',
                mt: 0.3,
                color: '#6aa2dc',
                transition: 'all .5s ease',
                animation: `${iconFade} 0.5s ease`,
              }}
              className="bx--down-arrow"
            />
          ) : (
            <Close
              sx={{
                ml: 0.5,
                fontSize: '1.6rem',
                mt: 0.3,
                color: '#ffffff',
                transition: 'all .5s ease',
                animation: `${iconFade} 0.5s ease`,
              }}
            />
          )}
        </Stack>
      )}
    </Link>
  );
};

ParentItem.propTypes = {
  title: PropTypes.string,
  hasSub: PropTypes.bool,
  handleToggle: PropTypes.func,
  isActive: PropTypes.bool,
};

const MegaMenuItem = ({ parent, open, handleClose }) => {
  const { title, children } = parent;
  const navigate = useNavigate();

  const handleNavigation = (item) => {
    if (title === 'Services') {
      navigate(`/services/${item.title1}`, { state: item });
    } else if (title === 'Industries') {
      navigate(`/industry/${item.title1}`, { state: item });
    } 
    else if (title === 'Solutions') {
      navigate(`/solutions/${item.title1}`, { state: item });
    } else {
      navigate(item.path);
    }
  };

  return (
    <>
      <ParentItem title={title} hasSub={!!children} handleToggle={handleClose} isActive={open} />
      {open && (
        <Paper
          sx={{
            p: 3,
            width: '100%',
            position: 'absolute',
            borderRadius: 'none',
            top: ITEM_HEIGHT,
            left: -ITEM_SPACING * 8,
            zIndex: (theme) => theme.zIndex.modal,
            boxShadow: (theme) => theme.customShadows.z20,
          }}
        >
          {children && (
            <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }} height={CONTENT_HEIGHT}>
              {children.map((list, index) => {
                const { items } = list;

                return (
                  <Stack key={index} spacing={1.25} sx={{ mb: 2.5 }}>
                    {items.map((link) => (
                      <Link
                        noWrap
                        key={link.title}
                        component="button"
                        onClick={() => handleNavigation(link)}
                        underline="none"
                        sx={{
                          color: '#000049',
                          fontSize: '15px',
                          fontWeight: '450',
                          fontFamily: '"Gellix",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
                          padding: '15px',
                          transition: 'color 0.7s, background-color 0.7s',
                          display: 'flex',
                          alignItems: 'center',
                          backgroundColor: 'transparent',
                          '&:hover': {
                            backgroundColor: '#027dc2',
                            color: '#ffffff',
                            textDecoration: 'none',
                            '& .bx--right-arrow': {
                              color: '#ffffff',
                            },
                          }
                        }}
                      >
                        {link.title}
                        <Stack
                          sx={{
                            ml: 0.5,
                            fontSize: '1.4rem',
                            mt: 0.2,
                            color: '#6aa2dc',
                            transition: 'all .5s ease',
                            animation: `${iconFade} 0.5s ease`,
                          }}
                          className="bx--right-arrow"
                        />
                      </Link>
                    ))}
                  </Stack>
                );
              })}
            </Stack>
          )}
        </Paper>
      )}
    </>
  );
};

MegaMenuItem.propTypes = {
  parent: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

const MegaMenuDesktopHorizon = ({ navConfig }) => {
  const [openItem, setOpenItem] = useState(null);
  const location = useLocation();

  const handleToggle = (title) => {
    setOpenItem(openItem === title ? null : title);
  };

  useEffect(() => {
    setOpenItem(null);
  }, [location]);

  return (
    <Stack direction="row" spacing={ITEM_SPACING}>
      {navConfig.map((parent) => (
        <MegaMenuItem
          key={parent.title}
          parent={parent}
          open={openItem === parent.title}
          handleClose={() => handleToggle(parent.title)}
        />
      ))}
    </Stack>
  );
};

MegaMenuDesktopHorizon.propTypes = {
  navConfig: PropTypes.array,
};

export default MegaMenuDesktopHorizon;
